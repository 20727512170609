<template>
  <div>
    <button class="btn btn-danger" @click="mostrarModal"><h1> S.O.S </h1></button>

    <div class="modal" v-if="mostrarConfirmacion">
      <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h3 class="modal-title">¿Está seguro que desea enviar el S.O.S.?</h3>
            <button type="button" class="close text-white" @click="cancelarSOS">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h3><p>Chofer: {{ chofer }}</p></h3>
            <h3><p>Unidad: {{ unidad }}</p></h3>
            <button class="btn btn-danger btn-block" @click="enviarSOS"><h1>Sí</h1></button>
            <button class="btn btn-warning btn-block" @click="cancelarSOS"><h1>No</h1></button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="mostrarMensaje">
      <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h5 class="modal-title">Mensaje</h5>
            <button type="button" class="close text-white" @click="cerrarMensaje">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ mensaje }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import config from '../../public/config.json'
  export default {
  props: {
    chofer: {
      type: String,
      required: true
    },
    unidad: {
      type: String,
      required: true
    }
  },
    data() {
      return {
        mostrarConfirmacion: false,
        mostrarMensaje: false,
        mensaje: "",
      };
    },
    methods: {
      mostrarModal() {
        this.mostrarConfirmacion = true;
      },
      cancelarSOS() {
        this.mostrarConfirmacion = false;
      },

      cerrarMensaje() {
        this.mostrarMensaje = false;
      },
      enviarSOS () {
      var self = this
      fetch(
        'http://' + config.ip + '/logistica/INS_SOS/params;chofer=' + this.chofer + 
        ';uutt=' + this.unidad
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: self.token
          },
          body: JSON.stringify({
          })
        })
        .then(async response => {
          const rtas = await response.json()
          console.log(rtas)
          // Llamar al método para enviar el S.O.S.
            this.mostrarConfirmacion = false;
            this.mensaje = "Se envió exitosamente";
            this.mostrarMensaje = true;
            setTimeout(() => {
            this.mostrarMensaje = false;
            }, 2000);
        })
        .catch(error => {
          console.log('error:enviarSOS')  
          console.log(error);
        })
    }
    },
  };
  </script>
  
  <style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 20px;
  border: none;
  border-radius: 25;
  box-shadow: none;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.close {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn {
  margin-right: 10px;
  height: 80px;
  border-radius: 25px;
}
</style>
  