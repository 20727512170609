import Vue from 'vue';
import store from './components/store'
import App from './App.vue';
import router from './routes/index';

// Importar estilos
import './App.css';
import './responsive.css';
import './assets/fonts/googlefonts.css';

// Bootstrap
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Gráficos
import VueCharts from 'vue-chartjs';

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueCharts);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
