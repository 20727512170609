<!-- ComponentePadre.vue -->
<template>
    <div>
      <ComponenteSOS :chofer="'37926100'" :unidad="'022'" />
    </div>
  </template>
  
  <script>
  import ComponenteSOS from './ComponenteSOS.vue';
  
  export default {
    components: {
      ComponenteSOS,
    },
  };
  </script>
  