import Vue from 'vue'
import Vuex from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    area: null,
    ip: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.token = token
    },
    setArea (state, area) {
      state.area = area
    },
    setIp (state, ip) {
      state.ip = ip
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isLoggedIn (state) {
      return !!state.token
    },
    getToken (token) {
      return token
    },
    getArea (area) {
      return area
    }
  },
  plugins: [
    createMultiTabState()
  ]
})
