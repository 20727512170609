<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5"></div>
       <div class="col-md-3" style="margin-top: 70px;">
        <img src="../assets/img/logo.svg" alt="Graficos" title="Graficos">
         <h3>Iniciar sesión</h3>
         <b-form @submit.prevent='login'>
           <b-form-input v-model='username' placeholder='Usuario' />
           <br />
           <b-form-input v-model='password' placeholder='Clave' type='password' />
           <br />
           <b-button type='submit' variant='primary'>INICIAR</b-button>
         </b-form>
       </div>
       <div class="col-md-5"></div>
   </div>
 </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
// import shared from '../components/shared/shared'
import config from '../../public/config.json'
export default {
  name: 'Login',
  props: {
    ticket: String
  },
  created () {
    document.title = 'Inicio de sesion'
  },
  // extends: shared,
  data: () => {
    return {
      // loaded: false,
      username: '',
      password: ''
    }
  },
  mounted () {
  },
  beforeMount () {
    if (this.$route.query.jwt) {
      this.setToken(this.$route.query.jwt)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setArea']),
    async login (e) {
      console.log(config.ip)
      e.preventDefault()
      fetch('http://' + '34.70.195.137:8080'/*config.ip*/ + '/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usrNombre: this.username,
          usrClave: this.password
        })
      })
        .then(async response => {

          const { user, area, JWT } = await response.json()

          console.log(user)
          this.setUser(user)
          this.setArea(area)
          this.setToken(JWT)
          // this.successMsg('Acceso Correcto, bienvenido ' + user)
          console.log('Acceso Correcto, bienvenido ' + user)
          this.$router.push('/')
        })
        .catch(error => {
          this.setToken(null)
          this.setUser(null)
          this.setArea(null)
          console.log('Mensaje de error: ', error)
          // this.errorMsg(error)
        })
    }
  }
}
</script>
<style scoped>
body
{
    margin:auto;
    width:1024px;
    padding:10px;
    background-color:#4d3737;
    font-size:14px;
    font-family:Verdana;
}
</style>
