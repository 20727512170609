import Login from '../components/Login.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Checklist from '../components/Checklist.vue';
import extras from '../components/Extras.vue'


Vue.use(VueRouter);

const routes = [
  {path: '/login', component: Login},
  {path: '/', component: Checklist},
  {path: '/extras', component: extras}
  /*{
    path: '/',
    name: 'Checklist',
    component: Checklist,
    props: (route) => ({
      truckNumber: route.query.UT,
      fleet: route.query.flota
    })
  }*/
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
export default router;
