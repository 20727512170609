<template>
  <div class="container">
    <div
      v-if="$route.query.feedback"
      class="card text-white bg-dark rounded-lg shadow"
    >
      <div class="card-body">
        
        <h5 class="card-title">Datos feedback APP</h5>
        <p class="card-text">CheckList Unidad (#{{ truckNumber }})</p>
        <p class="card-text">token: (#{{ token }})</p>
        <p class="card-text">DNI: (#{{ dni }})</p>
        <p class="card-text">Tipo de checklist: {{ type }}</p>
        <p v-if="checklistEnviado" class="card-text" style="color: yellow">
          Check list registrado con éxito
        </p>
      <p class="card-text">unidadValida: {{unidadValida}}</p>
      <p class="card-text">RespuestaNegativa: {{RespuestaNegativa}}</p>
      <p class="card-text">ofrecerLlamarALogistica: {{ofrecerLlamarALogistica}}</p>
      <p class="card-text">currentQuestion: {{currentQuestion}}</p>
      </div>
    </div>
    <div class="card-body text-white bg-dark rounded-lg shadow">
      <div class="d-flex align-items-center">
        <button
          class="btn btn-danger col-2"
          style="
            border-radius: 15px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="BackToChferesApp()"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="32"  fill="#ffffff" class="bi bi-backspace-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        </button>

        <h1 style="padding-left: 15px;" class="col">{{ TituloTipoCHlist }}</h1>
      </div>
    </div>
    <div v-if="checklistCompleted">
      <div class="card text-white bg-dark rounded-lg shadow">
        <div class="card-body">
          <h3 class="card-title">Datos recopilados:</h3>
          <div class="Row">
            <p class="card-text">
              Unidad: <b style="color: yellow">{{ truckNumber }}</b>
            </p>
            <p class="card-text">
              Chofer: <b style="color: yellow">{{ dni }}</b>
            </p>
            <h4>Vista Previa:</h4>
            <ul>
              <li v-for="question in questions" :key="question.title">
                <p class="card-text">
                  {{ question.title }} - Respuesta:
                  {{ question.answer ? "Sí" : "No" }}
                </p>
                <ul v-if="question.checkedItems.length > 0">
                  <li v-for="item in question.checkedItems" :key="item">
                    <p class="card-text">{{ item }}</p>
                  </li>
                </ul>
              </li>
            </ul>
            <!---<textarea
                      v-model="observation"
                      placeholder="Escriba aqui si desea enviar una observacion junto al CheckList"
                      style="width: 100%; height: 4em;"
                    ></textarea>-->
          </div>
          <div class="row" v-if="!ofrecerLlamarALogistica">
            <div class="col d-flex justify-content-center">
              <b-button
                class="btn btn-success m-1"
                @click="submitChecklist"
                style="border-radius: 25px"
              >
                Guardar <br />checklist
              </b-button>
            </div>
            <div class="col d-flex justify-content-center">
              <b-button
                class="btn btn-warning m-1"
                @click="reloadPage()"
                style="border-radius: 25px"
              >
                Volver a realizar <br />
                el Check
              </b-button>
            </div>
          </div>
          <div class="row" v-if="ofrecerLlamarALogistica">
            <div class="col d-flex justify-content-center">
              <b-button
                v-if="RespuestaNegativa"
                class="btn btn-danger m-1 d-flex align-items-center justify-content-center"
                @click="llamaraTaller()"
                style="border-radius: 25px"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
                <div>
                  llamar a <br />
                  taller
                </div>
              </b-button>
            </div>
            <div class="col d-flex justify-content-center">
              <b-button
                v-if="RespuestaNegativa"
                class="btn btn-warning m-1 d-flex align-items-center justify-content-center"
                @click="BackToChferesApp()"
                style="border-radius: 25px"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
                <div>
                  Salir del <br />
                  CheckList
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <br />
      <div v-if="currentQuestionIndex < questions.length" class="row">
        <div class="col-sm-12">
            <h2 class="question">{{ currentQuestion.title }}</h2>
        </div>
        <div class="col d-flex justify-content-center">
          <b-button
            class="btn btn-success btn-block m-1"
            style="height: 80px; border-radius: 25px"
            @click="answerQuestion(true)"
            ><h1>SI</h1></b-button
          >
        </div>
        <div class="col d-flex justify-content-center">
          <b-button
            class="btn btn-danger btn-block m-1"
            style="height: 80px; border-radius: 25px"
            @click="answerQuestion(false)"
            ><h1>NO</h1></b-button
          >
        </div>
      </div>
      <div v-else>
        <h2 v-if="unidadValida">¡Checklist completado!</h2>
        <b-button @click="submitChecklist" v-if="unidadValida">Enviar</b-button>
      </div>

      <div v-if="showSubQuestions" class="checklist-container">
        <h3>Detalles:</h3>
        <ul>
          <li v-for="item in currentQuestion.items" :key="item">
            <label class="sub-question-label">
              <b-form-checkbox
                v-model="currentQuestion.checkedItems"
                :value="item"
                size="lg"
              >
                <h3
                  :class="{
                    'yellow-text': currentQuestion.checkedItems.includes(item),
                  }"
                >
                  {{ item }}
                </h3>
              </b-form-checkbox> </label
            ><br />
          </li>
        </ul>
        <b-button
          class="btn btn-success btn-block m-1"
          style="height: 80px; border-radius: 25px"
          @click="nextQuestion"
          ><h1>Siguiente</h1></b-button
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
import { parseString } from "xml2js";
import axios from "axios";
import config from "../../public/config.json";
export default {
  mounted() {
    this.truckNumber = this.$route.query.uutt;
    this.token = this.$route.query.token;
    this.dni = this.$route.query.dni;
    // si no se paso el parametro por defecto es una toma de unidad
    if (this.$route.query.type === null) {
      this.type = "tomaunidad"; // la otra opcion deberia ser "tecnico"
    } else {
      this.type = this.$route.query.type;
    }

    //este parametro se utiliza para consultar que cuestionario traer
    //desde la app de choferes solo se sabe si es toma de unidad o tecnico
    //por getflota se averigua si es corta o larga
    this.GetFlota();
  },
  data() {
    return {
      truckNumber: null,
      fleet: null,
      questions: [],
      currentQuestionIndex: 0,
      showSubQuestions: false,
      checklistStarted: false,
      checklistCompleted: false,
      token: "",
      dni: "",
      submitted: false,
      observation: "",
      type: null,
      checklistEnviado: false,
      TituloTipoCHlist: "",
      unidadValida: false,
      RespuestaNegativa: false,
      ofrecerLlamarALogistica: false,
      phoneTaller: 1138229515
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
  },
  methods: {
    startChecklist() {
      this.checklistStarted = true;
    },
    convertXMLtoJSON(xmlFromAPI) {
      parseString(xmlFromAPI, (err, result) => {
        if (err) {
          console.error(err);
        } else {
          const preguntas = result.Checklist.Pregunta;
          const checklistData = preguntas.map((pregunta) => {
            const question = {
              title: pregunta.Pregunta[0],
              answer: null,
              items: [],
              checkedItems: [],
            };

            if (pregunta.Detalles && pregunta.Detalles[0]) {
              const detalles = pregunta.Detalles[0].split("@");
              question.items = detalles.slice(1);
            }

            return question;
          });

          this.questions = checklistData;
        }
      });
    },
    answerQuestion(answer) {
      this.currentQuestion.answer = answer;
      if (answer === false) {
        this.showSubQuestions = true;
        this.RespuestaNegativa = true;
      } else {
        this.nextQuestion();
      }
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.showSubQuestions = false;
      } else {
        this.checklistCompleted = true;
      }
    },
    reloadPage() {
      location.reload();
    },
    getFlotaTitle() {
      if (this.fleet === "C") {
        this.unidadValida = true;
        return "Check List Corta distancia";
      } else if (this.fleet === "L") {
        this.unidadValida = true;
        return "Check List Larga distancia";
      } else {
        this.unidadValida = false;
        return this.fleet;
      }
    },
    GetCheckList() {
      var self = this;
      var url = "http://" + config.ip + "/logistica/getCheckLists/" + self.type;
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      axios
        .get(url, {
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          credentials: "include",
        })
        .then(function (response) {
          const preguntas = response.data;
          //console.log(preguntas)
          self.convertXMLtoJSON(preguntas);
          self.checklistStarted = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetFlota() {
      var self = this;

      var url =
        "http://" +
        config.ip +
        "/logistica/getFlota/params;unidad=" +
        self.truckNumber;
      console.log(url);
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      axios
        .get(url, {
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: self.token,
          },
          mode: "no-cors",
          credentials: "include",
        })
        .then(function (response) {
          const flota = response.data[0].flota; // Accede al valor de "flota" en el primer objeto del array
          self.fleet = flota;
          // console.log(self.fleet);
          //---------------validacion
          if (self.fleet === "C" || self.fleet === "L") {
            if (self.type === "tomaunidad") {
              if (self.fleet === "C") {
                self.type = "CORTA";
                self.TituloTipoCHlist = "Check list Corta";
                self.unidadValida = true;
              }
              if (self.fleet === "L") {
                self.type = "LARGA";
                self.TituloTipoCHlist = "Check list Larga";
                self.unidadValida = true;
              }
            } else {
              self.type = "TECNICO";
              self.TituloTipoCHlist = "Check list Tecnico";
              self.unidadValida = true;
            }
          } else {
            self.unidadValida = false;
            // console.log(self.fleet);
          }
          //---------------validacion
          if (self.unidadValida) {
            self.GetCheckList();
          }
        })
        .catch(function (error) {
          console.log("error:GetFlota");
          console.log(self.fleet);
          self.TituloTipoCHlist = self.fleet;
          console.log(error);
        });
    },
    BackToChferesApp() {
      window.location.href ="http://choferes.grupopenna.com.ar/?jwt=" + this.token + "&dni=" + this.dni;
    },
    submitChecklist() {
    var self = this;
    fetch(
        "http://" +
        config.ip +
        "/logistica/INS_RPTAS/params;unidad=" +
        self.truckNumber +
        ";dni=" +
        self.dni +
        ";tipo=" +
        self.type +
        ";observ=" +
        encodeURIComponent(self.observation),
        {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
        },
        body: JSON.stringify({}),
        }
    )
    .then(async (response) => {
        const rtas = await response.json();
        console.log(rtas[0].result);
        if (rtas[0].result === "ok") {
            const nro = rtas[0].rpts_id;
            this.questions.forEach((question, index) => {
                index = index + 1;
                console.log(
                "INS_RPTAS_preg: " +
                    nro +
                    "," +
                    index +
                    "," +
                    question.title +
                    "," +
                    (question.answer ? "Sí" : "No")
                );
                self.INS_RPTAS_preg(
                nro,
                index,
                question.title,
                question.answer ? "Sí" : "No"
                );
                if (question.checkedItems.length > 0) {
                    question.checkedItems.forEach((item) => {
                        console.log('question.checkedItems.length > 0')
                        self.INS_RPTAS_preg_itm(nro, index, item);
                        self.ofrecerLlamarALogistica = true
                    });
                }
            });

            // Android Interface
            /* eslint-disable no-undef */
            AndroidInterface.evento(
                "Se registro el Checklist para la unidad " + self.truckNumber + ";" + nro
            );
            /* eslint-enable no-undef */

            // Enviar el correo después de que se hayan recorrido todas las preguntas
            self.EnviarMail();
        } else {
            alert(rtas[0].result);
        }
    })
    .catch((error) => {
        console.log("error:submitChecklist");
        console.log(error);
    });
},
    INS_RPTAS_preg(nro, itm, pregunta, rpta) {
      var self = this;
      fetch(
        "http://" +
          config.ip +
          "/logistica/INS_RESPI/params;id=" +
          nro +
          ";itm=" +
          itm +
          ";pregunta=" +
          encodeURIComponent(pregunta) +
          ";respuesta=" +
          encodeURIComponent(rpta),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
          },
          body: JSON.stringify({}),
        }
      )
        .then(async (response) => {
          const rtas = await response.json();
          console.log();
          console.log("INS_RPTAS_preg" + rtas[0].result); /*
            if (rtas[0].result==='ok'){
              
              self.checklistEnviado=true
              self.BackToChferesApp()
            } else {
              alert(rtas[0].result)
            }*/
        })
        .catch((error) => {
          console.log("error:INS_RPTAS_preg");
          console.log(error);
        });
    },
    INS_RPTAS_preg_itm(nro, itm, observ) {
      var self = this;
      fetch(
        "http://" +
          config.ip +
          "/logistica/INS_RESPJ/params;id=" +
          nro +
          ";itm=" +
          itm +
          ";observ=" +
          encodeURIComponent(observ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
          },
          body: JSON.stringify({}),
        }
      )
        .then(async (response) => {
          const rtas = await response.json();
          console.log("INS_RPTAS_preg_itm" + rtas[0].result + " | " + observ);
        })
        .catch((error) => {
          console.log("error:INS_RPTAS_preg_itm");
          console.log(error);
        });
    },
    llamaraTaller() {
      /* eslint-disable no-undef */
      AndroidInterface.hacerLlamada('1157290566')
      /* eslint-disable no-undef */
    },
    EnviarMail() {
    console.log('EnviarMail')
    let content = `Unidad: ${this.truckNumber}\nChofer: ${this.dni}\n\nVista Previa:\n`;
    let hasNegativeAnswer = false

    for (let question of this.questions) {
      content += `\n${question.title} - Respuesta: ${question.answer ? 'Sí' : 'No'}\n`;
      // Si la respuesta es 'No', establece hasNegativeAnswer en true
      if (question.answer === false) {
          hasNegativeAnswer = true;
      }

      if (question.checkedItems.length > 0) {
        for (let item of question.checkedItems) {
          content += `\n${item}\n`;
        }
      }
    }

// Ahora, puedes usar hasNegativeAnswer para saber si alguna respuesta fue 'No'
    if (hasNegativeAnswer) {
      var self = this;
      self.ofrecerLlamarALogistica = true
      let contentToSend = content.replace(/\n/g, '__N__');
          fetch(
            "http://" +
              //config.ip +
              "34.67.86.78:8080" +
              "/logistica/Send_mail_chlist/params;mensaje=" +  encodeURIComponent(contentToSend) + ";dni=" + self.dni + ";uutt=" + self.truckNumber,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: self.token
              },
              body: JSON.stringify({
              }),
            }
          )
            .then(async (response) => {
              console.log('enviado')
              const rtas = await response.json();
              console.log("EnviarMail" + rtas[0].result + " | " + observ);
              AndroidInterface.evento(
              "Se envio mail por Checklist uutt:" + self.truckNumber
            );
            })
            .catch((error) => {
              console.log("error:EnviarMail");
              console.log(error);
            });
      }else{
              this.BackToChferesApp();
            }
    },
  },
};
</script>

<style scope>
/* Estilos generales para la apariencia de aplicación móvil */
.container {
  margin: 0 auto;
  padding: 16px;
  max-width: 480px;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.double-height {
  height: 4sem;
}

/* Estilos específicos para Checklist.vue */
.checklist-container {
  margin-top: 16px;
}

.question {
  margin-bottom: 16px;
  font-weight: bold;
}

.sub-question {
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: normal;
}

.sub-question-label {
  display: block;
  margin-bottom: 4px;
}

.sub-question-checkbox {
  margin-right: 8px;
}
.icon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 5.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
textarea {
  background-color: rgb(255, 255, 255);
  color: black;
  font-weight: bold;
  border-radius: 10px;
}
.yellow-text {
  color: yellow;
}

.ultraBlack {
  background-color: rgb(0, 0, 0);
}
.b-button {
  border-radius: 60px;
}
.col {
  padding-right: 5px;
  padding-left: 5px;
}
</style>