<template>
  <div id="app" class="dark-mode">
    <router-view ></router-view>
  </div>
</template>

<script>
//import Checklist from './components/Checklist.vue';

export default {
  name: 'App',
  components: {
    //Checklist
  }
};
</script>
<style>
.dark-mode {
  /* Estilos para el modo oscuro */
  background-color: #212121;
  color: #ffffff;
}

.dark-mode #titulo {
  /* Estilos específicos para el título en modo oscuro */
  color: #ffffff;
}

/* Resto de estilos para el modo oscuro */
/* ... */
</style>